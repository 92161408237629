import { useState, useEffect } from 'react'
import { getMemberBaseSafaVerifyBaseInfo } from '@/apis/user'
import { MemberBaseSafaVerifyAccountResp } from '@/typings/user'

const useMemberBaseSafaVerify = () => {
  const [memberBaseSafaVerify, setMemberBaseSafaVerify] = useState<MemberBaseSafaVerifyAccountResp>()

  useEffect(() => {
    const fetchData = async () => {
      const res = await getMemberBaseSafaVerifyBaseInfo({})
      setMemberBaseSafaVerify(res?.data)
    }
    fetchData()
  }, [])

  return memberBaseSafaVerify
}

export default useMemberBaseSafaVerify
